<template>
	<a-row type="flex">
	<a-col :span="24" class="font-10">
		{{ $t('title.language')}}
		<span>&nbsp;</span>
		<a-select
				size="small"
				style="width: 120px"
				v-model:value="$i18n.locale"
		>
			<a-select-option
					v-for="(lang, i) in langs"
					:key="`lang-${i}`"
					:value="lang.value">{{ lang.title }}</a-select-option>
		</a-select>
	</a-col>
	</a-row>

</template>

<script>


    import { itemExists, itemListExists } from "@/app/helpers";

export default {
    components: {},
    name: "locale-switcher",
  props: {


  },

  data() {
    return {

        langs: [
            {
            title: 'English',
			value: 'en'
		},
			{
			    title: "Setswana",
				value: 'ts'
			},
			{
			    title: "Shekgalagari",
				value: 'kga'
			},
			{
			    title: "iKalanga",
				value: 'kal'
			},
            {
                title: "Shiyeyi",
                value: 'shi'
            }
		]
    };
  },
  computed:{

  },

  methods: {

  },
  mounted() {

  },
  watch: {

  }
};
</script>
